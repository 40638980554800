
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="hero d-flex align-items-center">

    <div class="container">     
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">Modern Microsoft cloud solutions for growing your business</h1>
          <h2 data-aos="fade-up" data-aos-delay="400">We are a team of experienced technologists creating solutions with Microsoft cloud technoligies with a focus on small to medium size organizations.  We specialize in Microsoft Dynamics CRM, Power BI and .Net Full Stack Development.</h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <a href="#about" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Get Started</span>
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
          <img src="assets/img/hero-img.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">
    <!-- ======= About Section ======= -->
    <section id="about" class="about">

      <div class="container" data-aos="fade-up">
        <div class="row gx-0">

          <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div class="content"  style="margin-top: 50px;">
              <h3>Who We Are</h3>
              <h2>365Integrate is a team of Microsoft Dynamics and Power BI experts. </h2>
              <p>
                Our focus is to provide highly scalable cloud based solutions utilizing Microsoft technologies.  Whether it's implementing a Microsoft Dynamics 365 CRM for you sales department or providing analytics with Microsoft Power BI, our team will work with you to develop the optimal solution. 
              </p>
              <div class="text-center text-lg-start">
                <a href="#services" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Services</span>
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" >
            <img src="assets/img/about-2.jpg" class="img-fluid" alt="">
          </div>

        </div>
      </div>

    </section><!-- End About Section -->

    <!-- ======= Values Section ======= -->
    <section id="values" class="values">

      <div class="container" data-aos="fade-up">

        <header class="section-header" style="margin-top:50px;">
          <h2>Values</h2>
          <p>Our Core Values</p>
        </header>

        <div class="row">

          <div class="col-lg-4">
            <div class="box" data-aos="fade-up" data-aos-delay="200">
              <img src="assets/img/values-1.png" class="img-fluid" alt="">
              <h3>Communication</h3>
              <p>Our goal is to work with you by providing ongoing communication so that expectation are met and exceeded. </p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0">
            <div class="box" data-aos="fade-up" data-aos-delay="400">
              <img src="assets/img/values-2.png" class="img-fluid" alt="">
              <h3>Quality</h3>
              <p>We strive to deliver solutions that are impactful, scalable and manageable.  </p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0">
            <div class="box" data-aos="fade-up" data-aos-delay="600">
              <img src="assets/img/values-3.png" class="img-fluid" alt="">
              <h3>Innovative</h3>
              <p>Develop Microsoft cloud based solutions leveraging from proven Microsoft technologies.</p>
            </div>
          </div>

        </div>

      </div>

    </section><!-- End Values Section -->

  
    <!-- ======= Services Section ======= -->
    <section id="services" class="services">

      <div class="container" data-aos="fade-up"  style="margin-top: 50px;">

        <header class="section-header">
          <h2>Services</h2>
          <p>Microsoft  Technologies</p>
        </header>

        <div class="row gy-4">

          <div class="col-lg-4 col-md-4" data-aos="fade-up" data-aos-delay="200">
            <div class="service-box blue">
              <i class="ri-discuss-line icon"></i>
              <div class="text-center">
              <h3 >Microsoft Dynamics</h3>
              <p >Microsoft Dynamics 365 CRM implementation solutions for your enterprise, including custom implementations, integrations processes, migrations and analytics.</p>
              <!--<a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>-->
            </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4" data-aos="fade-up" data-aos-delay="300">
            <div class="service-box orange">
              <div class="text-center">
              <i class="ri-discuss-line icon"></i>
              <h3 >Microsoft Power BI</h3>
              <p >Are experts will work with you to design and implement Microsoft Power BI solutions by integrating disparate data source into highly dynamic and intelligent dashboards.  </p>
             <!-- <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>-->
            </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4" data-aos="fade-up" data-aos-delay="400">
            <div class="service-box green">
              <i class="ri-discuss-line icon"></i>
              <div class="text-center">
              <h3 >.Net Full Stack Development</h3>
              <p >Delivering reliable .NET-based web/mobile solutions.  Our team of .NET experts will help you to automate existing workflows, streamline business processes, and facilitate data management. </p>
              <!--<a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>-->
            </div>
            </div>
          </div>
          <!--
          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div class="service-box red">
              <i class="ri-discuss-line icon"></i>
              <h3>Asperiores Commodi</h3>
              <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div class="service-box purple">
              <i class="ri-discuss-line icon"></i>
              <h3>Velit Doloremque.</h3>
              <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
            <div class="service-box pink">
              <i class="ri-discuss-line icon"></i>
              <h3>Dolori Architecto</h3>
              <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
        -->

        </div>

      </div>

    </section><!-- End Services Section -->

    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing">

      <div class="container" data-aos="fade-up"  style="margin-top: 50px;">

        <header class="section-header">
          <h2>Pricing</h2>
          <p>Check our Pricing</p>
        </header>

        <div class="row gy-4" data-aos="fade-left">
<!--
          <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
            <div class="box">
              <h3 style="color: #07d5c0;">Free Plan</h3>
              <div class="price"><sup>$</sup>0<span> / mo</span></div>
              <img src="assets/img/pricing-free.png" class="img-fluid" alt="">
              <ul>
                <li>Aida dere</li>
                <li>Nec feugiat nisl</li>
                <li>Nulla at volutpat dola</li>
                <li class="na">Pharetra massa</li>
                <li class="na">Massa ultricies mi</li>
              </ul>
              <a href="#" class="btn-buy">Buy Now</a>
            </div>
          </div>-->

          <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div class="box">
              <span class="featured">Featured</span>
              <h3 style="color: #65c600;">Dynamics CRM Implementation</h3>
              <div class="price"><sup>$</sup>Basic Plan</div>
              <img src="assets/img/pricing-starter.png" class="img-fluid" alt="">
              <ul  class="list-group">
                <li class="list-group-item disabled bg-primary text-light ">Details</li>
                <li  class="list-group-item">CRM cloud setup and configuration</li>
                <li  class="list-group-item">Import Contacts from Existing List</li>
                <li  class="list-group-item"><b>Does not</b> include customizations</li>
                
              </ul>
              <a href="#contact" >Contact Us</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div class="box">
              <h3 style="color: #ff901c;">Dynamics CRM Implementation</h3>
              <div class="price"><sup>$$</sup>Basic Plus Plan<span></span></div>
              <img src="assets/img/pricing-business.png" class="img-fluid" alt="">
              <ul class="list-group">
                  <li class="list-group-item disabled bg-primary text-light ">Details</li>
                <li   class="list-group-item">Setup and configuration</li>
                <li  class="list-group-item">Import Contacts from Existing List</li>
                <li   class="list-group-item">Limited customizations based on needs </li>
              </ul>
              <a href="#"  class="nav-link scrollto" href="#contact">Contact Us</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div class="box">
              <h3 style="color: #ff0071;">Dynamics CRM Implementation</h3>
              <div class="price"><sup>$$$</sup>Custom Plan<span></span></div>
              <img src="assets/img/pricing-ultimate.png" class="img-fluid" alt="">
              <ul class="list-group">
                <li class="list-group-item disabled bg-primary text-light ">Details</li>
                <li   class="list-group-item">Includes Basic Plus Plan</li>
                <li   class="list-group-item">Extensive Customization based on needs</li>
                <li   class="list-group-item">Support based on needs</li>
              </ul>
              <a href="#"  class="nav-link scrollto" href="#contact">Contact Us</a>
            </div>
          </div>

        </div>

      </div>

    </section><!-- End Pricing Section -->


    <!-- ======= Team Section ======= -->
    <section id="team" class="team">

      <div class="container" data-aos="fade-up">

        <header class="section-header"  style="margin-top: 50px;">
          <h2>Team</h2>
          <p>Our talented team</p>
        </header>

        <div class="row gy-4">

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="member">         
             
           
              <div class="member-info card"  style="background-color: #C1FFC1;">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
                <p >Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member">              
              <div class="member-info card" style="background-color: #C1FFC1;">
                <h4>Debbie Johnson</h4>
                <span>Solutions Architect</span>
                <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="member">            
              <div class="member-info card" style="background-color: #C1FFC1;">
                <h4>Steve Smith</h4>
                <span>Project Manager</span>
                <p >Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div class="member">              
              <div class="member-info card" style="background-color: #C1FFC1;">
                <h4>Amanda Wilson</h4>
                <span>Senior Dynamics Developer</span>
                <p>Rerum voluptate non adipisci animi distinctio et deserunt amet voluptas. Quia aut aliquid doloremque ut possimus ipsum officia.</p>
              </div>
            </div>
          </div>

        </div>

      </div>

    </section><!-- End Team Section -->

    <!-- ======= Clients Section ======= -->
    <!--
    <section id="clients" class="clients">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h2>Our Clients</h2>
          <p>Temporibus omnis officia</p>
        </header>

        <div class="clients-slider swiper-container">
          <div class="swiper-wrapper align-items-center">
            <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt=""></div>
            <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt=""></div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>

    </section>-->
    <!-- End Clients Section -->

    <!-- ======= Recent Blog Posts Section ======= -->
  <!--
    <section id="recent-blog-posts" class="recent-blog-posts">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h2>Blog</h2>
          <p>Recent posts form our Blog</p>
        </header>

        <div class="row">

          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-1.jpg" class="img-fluid" alt=""></div>
              <span class="post-date">Tue, September 15</span>
              <h3 class="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-2.jpg" class="img-fluid" alt=""></div>
              <span class="post-date">Fri, August 28</span>
              <h3 class="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-3.jpg" class="img-fluid" alt=""></div>
              <span class="post-date">Mon, July 11</span>
              <h3 class="post-title">Quia assumenda est et veritatis aut quae</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

        </div>

      </div>

    </section>
  -->
    <!-- End Recent Blog Posts Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">

      <div class="container" data-aos="fade-up" style="margin-top: 50px;">

        <header class="section-header">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </header>

        <div class="row gy-12"  >

          <div class="col-lg-12">

            <div class="row gy-4 text-center">
              <div class="col-md-4">
                <div class="info-box">
                  <i class="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>
                    3400 Cottage Way, Ste G2 #5600,<br>Sacramento , California 95825</p>
                </div>
              </div>
              <div class="col-md-4  text-center" >
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>(858) 598 3711</p>
                </div>
              </div>
              <div class="col-md-4  text-center">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p><a href="mailto:info@365Integrate.com">info@365Integrate.com</a></p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="info-box">
                  <i class="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Friday<br>9:00AM - 05:00PM</p>
                </div>
              </div>
            </div>
          </div>
<!--
          <div class="col-lg-6">
            <form action="forms/contact.php" method="post" class="php-email-form">
              <div class="row gy-4">

                <div class="col-md-6">
                  <input id='contactName' type="text" name="name" class="form-control" placeholder="Your Name" required>
                </div>
                <div class="col-md-6 ">
                  <input id='contactEmail' type="email" class="form-control" name="email" placeholder="Your Email" required>
                </div>
                <div class="col-md-12">
                  <input id='contactSubject' type="text" class="form-control" name="subject" placeholder="Subject" required>
                </div>

                <div class="col-md-12">
                  <textarea id='contactMessage' class="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Your message has been sent. Thank you!</div>

                  <a class="btn btn-primary" onclick="sendMail(getElementById('contactName').value, getElementById('contactSubject').value, getElementById('contactMessage').value )">Send Message</a>
                 
                </div>

              </div>
            </form>

          </div>
        -->

        </div>

      </div>

    </section><!-- End Contact Section -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer" class="footer" >
<!--
    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <h4>Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
          </div>
          <div class="col-lg-6">
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div>
        </div>
      </div>
    </div>
  -->

    <div class="footer-top" style="border-top: 2px solid cornflowerblue; background-color:lightgreen">
      <div class="container" >
        <div class="row gy-4">
          <div class="col-lg-6  footer-info">
            <a href="index.html" class="logo d-flex align-items-center">
              <img src="assets/img/logo.png" alt="">
              <span style="font-size:medium;" >365Integrate</span><span style="font-size: small;font-style: italic;">&nbsp; LLC</span>
            </a>
            <p>Copyright © 2021 365Integrate | Microsoft Dynamics Consultant<br> | Microsoft Power BI | All Rights Reserved</p>
            <!--
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram bx bxl-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin bx bxl-linkedin"></i></a>
            </div>
          -->
          </div>

<!--
          <div class="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul>
          </div>
        -->

          <div class="col-lg-6 col-md-12 footer-contact text-md-start">
            <h4>Contact Us</h4>
            <p>
              COMPANY ADDRESS: 3400 Cottage Way, Ste G2 #5600 <br>
              Sacramento , California 95825<br>
              United States <br><br>
              <strong>Phone:</strong> (858) 598 3711<br>
              <strong>Email:</strong> info@365Integrate.com<br>
            </p>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>365Integrate LLC</span></strong>. All Rights Reserved
      </div>
      
    </div>
  </footer><!-- End Footer -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

